<template lang="pug">
BasePane
  OmColorInput(
    size="lg"
    :backgroundSrc="backgroundSrc"
    typeOfComponent="OmImage"
    deviceSelector
    dsAllowed
  )
  Heading.mb-3.mt-2(expandableGap="12px" :open="clickableBackground")
    template(#titleTrailing)
      .font-weight-bold {{ $t('clickableBackground') }}
    template(#prependEnding)
      OmSwitch#clickableBackground(v-model="clickableBackground")
    template(#expandableContent)
      OmInput#url(
        :label="$t('url')"
        small
        placeholder="https://www.example.com/"
        v-model="redirectURL"
        :error="isRedirectUrlError"
      )
      heading(label)
        template(#titleTrailing)
          .label {{ $t('keepQueryParams') }}
        template(#prependEnding)
          OmSwitch#keepURLParameters(v-model="keepURLParameters")
        template(#help)
          span {{ $t('keepQueryParamsTooltip') }}
      Heading(label)
        template(#titleTrailing)
          .label {{ $t('openInNewTab') }}
        template(#prependEnding)
          OmSwitch#openInNewTab(v-model="openInNewTab")
      ReportAs(v-model="reportAs")
      CampaignGoalReach(v-model="campaignGoalReach")
  template(v-if="!isFloatingImage")
    Accordion(fix)
      template(#title)
        .font-weight-bold {{ $t('background') }}
      .color-wrapper
        OmColorInput(
          :label="$t('column')"
          property="$device.background.color"
          typeOfComponent="column"
          layout="col"
          dsAllowed
        )
        OmColorInput.mt-2(
          :label="$t('block')"
          property="$device.background.color"
          typeOfComponent="row"
          layout="col"
          dsAllowed
        )
        OmColorInput.mt-2(
          :label="$t('currentPage')"
          property="selectedPage.$device.background.color"
          typeOfComponent="global"
          layout="col"
          dsAllowed
        )
    hr.sidebar-spacing-top(v-if="!isFloatingImage")
  Accordion(fix)
    template(#title v-if="!isFloatingImage")
      .font-weight-bold.mb-3 {{ $t('sizeAndSpacing') }}
    RangeInput(:label="$t('width')" :min="10" :max="1000" :step="5" editMobile v-model="width")
    AlignDropdown(
      v-if="selectedElement.type !== 'OmFloatingImage'"
      v-model="alignment"
      layout="col"
      editMobile
    )
    InputGroup(
      v-if="!isFloatingImage"
      :label="$t('margin')"
      :keysWithValue="marginKeys"
      :allKeysToggled="allKeysToggled"
      :placeholders="marginPlaceholders"
      @all-keys-change="handleMarginAllSideChange"
      @input="handleMarginChange"
      editMobile
    )
  hr.sidebar-spacing-top.sidebar-spacing-bottom
  template(#advancedContent)
    Hide.sidebar-spacing-top(
      :hideOnDesktop.sync="hideElementOnDesktop"
      :hideOnMobile.sync="hideElementOnMobile"
    )
    Border(typeOfComponent="image")
    CornerRadius
    Shadow(
      v-model="shadow"
      editMobile
      coloringProperty="$device.shadow.color"
      typeOfComponent="image"
    )
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import { ELEMENTS } from '@/../../../libraries/template-properties/src/propertyHelper';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import ReportAs from '@/components/Editor/Controls/ReportAs.vue';
  import CampaignGoalReach from '@/components/Editor/Controls/CampaignGoalReach.vue';
  import AlignDropdown from '@/components/Editor/Controls/Alignments/AlignDropdown.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import InputGroup from '@/components/Editor/Controls/InputGroup.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';

  export default {
    components: {
      BasePane,
      Heading,
      Accordion,
      RangeInput,
      ReportAs,
      CampaignGoalReach,
      AlignDropdown,
      Shadow,
      Border,
      InputGroup,
      CornerRadius,
      Hide,
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'images', 'mobilePreview', 'themeKit', 'validationError']),
      ...mapGetters(['nestedAccess', 'selectedImage', 'isEmbedded']),
      reportAs: {
        get() {
          return this.getValueOf('data.reportAs');
        },
        set(value) {
          this.setValueOf('data.reportAs', value);
        },
      },
      campaignGoalReach: {
        get() {
          return this.getValueOf('data.goalReached');
        },
        set(v) {
          this.setValueOf('data.goalReached', v);
        },
      },
      alignment: {
        get() {
          return this.getValueOf('align');
        },
        set(value) {
          this.setValueOf('align', value);
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf('mobile.smartSize.width');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.smartSize.width'));
          }
          return Number(this.getValueOf('$device.smartSize.width'));
        },
        set(v) {
          this.smartSetValueOf('$device.smartSize.width', v);

          const calcWidthInEm = this.smartGetValueOf('mobile.calcWidthInEm');
          if (this.mobilePreview && !calcWidthInEm) {
            this.smartSetValueOf('mobile.calcWidthInEm', true);
          }
        },
      },
      clickableBackground: {
        get() {
          return this.getValueOf('data.clickableImage');
        },
        set(v) {
          this.setValueOf('data.clickableImage', v);
        },
      },
      redirectURL: {
        get() {
          return this.getValueOf('data.redirectUrl');
        },
        set(v) {
          this.setValueOf('data.redirectUrl', v);
        },
      },
      keepURLParameters: {
        get() {
          return this.getValueOf('data.keepQueryParams');
        },
        set(v) {
          this.setValueOf('data.keepQueryParams', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      openInNewTab: {
        get() {
          return this.getValueOf('data.newTab');
        },
        set(v) {
          this.setValueOf('data.newTab', v);
        },
      },
      customThemeRounding: {
        get() {
          return this.getValueOf('border.customThemeRounding');
        },
        set(v) {
          this.setValueOf('border.customThemeRounding', v);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      marginKeys() {
        return [
          { name: 'top', value: this.smartGetValueOf('$device.margin.top') },
          { name: 'right', value: this.smartGetValueOf('$device.margin.right') },
          { name: 'bottom', value: this.smartGetValueOf('$device.margin.bottom') },
          { name: 'left', value: this.smartGetValueOf('$device.margin.left') },
        ];
      },
      allKeysToggled() {
        return this.getValueOf('margin.allSides');
      },
      marginPlaceholders() {
        if (this.mobilePreview) {
          return [
            this.getValueOf('desktop.margin.top'),
            this.getValueOf('desktop.margin.right'),
            this.getValueOf('desktop.margin.bottom'),
            this.getValueOf('desktop.margin.left'),
          ];
        }
        return null;
      },
      isRedirectUrlError() {
        return this.validationError.property === 'data.redirectUrl';
      },
      isFloatingImage() {
        return this.selectedElement.type === ELEMENTS.OmFloatingImage;
      },
      hasMobileImage() {
        return this.getValueOf('mobile.background.imageId');
      },
      backgroundSrc() {
        if (this.mobilePreview && this.hasMobileImage) {
          return this.smartGetValueOf('$device.background.imageUrl');
        }
        return this.smartGetValueOf('desktop.background.imageUrl');
      },
    },
    methods: {
      handleMarginAllSideChange(isToggled) {
        this.setValueOf('margin.allSides', isToggled);
      },
      handleMarginChange({ key, value, allSides }) {
        if (allSides) {
          const keys = ['top', 'right', 'bottom', 'left'];
          keys.forEach((key) => {
            this.setValueOf(`$device.margin.${key}`, value);
          });
        } else {
          this.setValueOf(`$device.margin.${key}`, value);
        }
      },
    },
  };
</script>
